import scipAccountBalanceReport from "./scipAccountBalanceReport";
import scipAssessorsDataReport from "./scipAssessorsDataReport";
import scipDebtSchedulesReport from "./scipDebtSchedulesReport";
import scipDelinquencyDistrictReport from "./scipDelinquencyDistrictReport";
import scipDelinquencyReport from "./scipDelinquencyReport";
import scipDelinquencyFirstInstallmentReport from "./scipDelinquencyFirstInstallmentReport";
import scipRemainingLienReport from "./scipRemainingLienReport";
import scipYoyLevyReport from "./scipYoyLevyReport";

const scipReports = {
  scipAccountBalanceReport,
  scipAssessorsDataReport,
  scipDebtSchedulesReport,
  scipDelinquencyDistrictReport,
  scipDelinquencyReport,
  scipDelinquencyFirstInstallmentReport,
  scipRemainingLienReport,
  scipYoyLevyReport,
};

export default scipReports;
